import type * as BackInStockTypes from '@integrabeauty/back-in-stock-types';
import { isValidEmailAddress } from '@integrabeauty/person';
import * as uuid from 'uuid';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders a modal window that appears when a visitor clicks the "Notify Me" button associated with
 * a certain out-of-stock product. Within the modal, the user is prompted for their email address,
 * and upon submission, they are subscribed to receive future communications about the product's
 * availability.
 */
class NotifyMeModal extends HTMLElement {
  private email: string;
  private productId: number;
  private productTitle: string;
  private productUrl: string;
  private sku: string;
  private variantId: number;
  private variantTitle: string;
  private debounceTimeout: ReturnType<typeof setTimeout>;
  private onModalDisplayClickBound = this.onModalDisplayClick.bind(this);
  private onFormSubmitClickBound = this.onFormSubmitClick.bind(this);
  private onEmailInputBound = this.onEmailInput.bind(this);
  private validateEmailBound = this.validateEmail.bind(this);
  private closeModalBound = this.closeModal.bind(this);
  private onClearButtonClickBound = this.onClearButtonClick.bind(this);

  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    addEventListener('notify-me-clicked', this.onModalDisplayClickBound);

    const emailInputElement = this.shadowRoot.querySelector('.email input');
    emailInputElement.addEventListener('input', this.onEmailInputBound);
    emailInputElement.addEventListener('blur', this.validateEmailBound);

    const formSubmitButton = this.shadowRoot.querySelector('.submit');
    formSubmitButton.addEventListener('click', this.onFormSubmitClickBound);

    const modalCloseButton = this.shadowRoot.querySelector('.close');
    modalCloseButton.addEventListener('click', this.closeModalBound);

    const clearButton = this.shadowRoot.querySelector('.clear');
    clearButton.addEventListener('click', this.onClearButtonClickBound);
  }

  public disconnectedCallback() {
    removeEventListener('notify-me-clicked', this.onModalDisplayClickBound);

    const emailInputElement = this.shadowRoot.querySelector<HTMLInputElement>('.email input');
    emailInputElement?.removeEventListener('input', this.onEmailInputBound);
    emailInputElement?.removeEventListener('blur', this.validateEmailBound);

    const formSubmitButton = this.shadowRoot.querySelector('.submit');
    formSubmitButton?.removeEventListener('click', this.onFormSubmitClickBound);

    const modalCloseButton = this.shadowRoot.querySelector('.close');
    modalCloseButton?.removeEventListener('click', this.closeModalBound);

    const clearButton = this.shadowRoot.querySelector('.clear');
    clearButton?.removeEventListener('click', this.onClearButtonClickBound);
  }

  private closeModal() {
    const dialog = this.shadowRoot.querySelector<HTMLElement>('div[role="dialog"]');
    dialog.classList.add('hidden');
    dialog.setAttribute('inert', '');
    removeEventListener('shroud-clicked', this.closeModalBound);

    this.email = '';
    this.productId = 0;
    this.productTitle = '';
    this.productUrl = '';
    this.sku = '';
    this.variantId = 0;
    this.variantTitle = '';

    const emailInputElement: HTMLInputElement = this.shadowRoot.querySelector('.email input');
    emailInputElement.classList.remove('error');
    emailInputElement.value = '';
    const validationErrorMessage = this.shadowRoot.querySelector('.validation-error-message');
    validationErrorMessage.textContent = '';
    const submitErrorMessage = this.shadowRoot.querySelector('.submit-error-message');
    submitErrorMessage.textContent = '';
    const clearButton = this.shadowRoot.querySelector('.clear');
    clearButton.classList.add('hidden');
    const checkIcon = this.shadowRoot.querySelector('.check');
    checkIcon.classList.add('hidden');

    type ModalDetail = WindowEventMap['modal-close-requested']['detail'];
    const modalCloseEvent = new CustomEvent<ModalDetail>('modal-close-requested',
      { detail: { element: dialog } }
    );
    dispatchEvent(modalCloseEvent);
  }

  /**
   * Runs email validation on input but not on every stroke
   */
  private onEmailInput(_event: Event) {
    const validationErrorMessage = this.shadowRoot.querySelector('.validation-error-message');
    validationErrorMessage.textContent = '';
    const emailInputElement = this.shadowRoot.querySelector<HTMLInputElement>('.email input');
    emailInputElement.classList.remove('error');

    if (this.debounceTimeout !== null) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(this.validateEmailBound, 500);
  }

  /**
   * Validates email and display error messages if necessary
   */
  private validateEmail() {
    const emailInputElement = this.shadowRoot.querySelector<HTMLInputElement>('.email input');
    const email = emailInputElement.value.trim();
    const validationErrorMessage = this.shadowRoot.querySelector('.validation-error-message');
    const clearButton = this.shadowRoot.querySelector('.clear');
    const checkIcon = this.shadowRoot.querySelector('.check');

    if (isValidEmailAddress(email)) {
      validationErrorMessage.textContent = '';
      emailInputElement.classList.remove('error');
      clearButton.classList.add('hidden');
      checkIcon.classList.remove('hidden');
    } else {
      validationErrorMessage.textContent = 'Please enter valid email.';
      emailInputElement.classList.add('error');
      checkIcon.classList.add('hidden');
      if (emailInputElement.value === '') {
        clearButton.classList.add('hidden');
      } else {
        clearButton.classList.remove('hidden');
      }
    }
  }

  /**
   * Clears email input
   */
  private onClearButtonClick() {
    const emailInputElement = this.shadowRoot.querySelector<HTMLInputElement>('.email input');
    emailInputElement.value = '';
    const clearButton = this.shadowRoot.querySelector('.clear');
    clearButton.classList.add('hidden');
  }

  /**
   * Displays modal and reads detail data from the fired custom event
   */
  private onModalDisplayClick(event: WindowEventMap['notify-me-clicked']) {
    this.email = event.detail.email;
    this.productId = event.detail.productId;
    this.productTitle = event.detail.productTitle;
    this.productUrl = event.detail.productUrl;
    this.sku = event.detail.sku;
    this.variantId = event.detail.variantId;
    this.variantTitle = event.detail.variantTitle;

    this.renderFirstView();

    const dialog = this.shadowRoot.querySelector<HTMLElement>('div[role="dialog"]');
    dialog.removeAttribute('inert');
    dialog.classList.remove('hidden');
    addEventListener('shroud-clicked', this.closeModalBound);

    type Detail = WindowEventMap['modal-show-requested']['detail'];
    const modalShowEvent = new CustomEvent<Detail>('modal-show-requested',
      { detail: { element: dialog } }
    );
    dispatchEvent(modalShowEvent);
  }

  private renderFirstView() {
    const modalContentToShow = this.shadowRoot.querySelector('.before-submit');
    modalContentToShow.classList.remove('hidden');
    const modalContentToHide = this.shadowRoot.querySelector('.after-submit');
    modalContentToHide.classList.add('hidden');

    // Pre-fill email if the user is logged in
    const inputElement = this.shadowRoot.querySelector<HTMLInputElement>('.email input');
    if (!inputElement.value && this.email) {
      inputElement.value = this.email;
    }

    inputElement.focus();
  }

  private async subscribe() {
    const inputElement = this.shadowRoot.querySelector<HTMLInputElement>('.email input');
    const marketingElement = this.shadowRoot.querySelector<HTMLInputElement>('#marketing-consent');
    const errorElement = this.shadowRoot.querySelector('.submit-error-message');

    // Reset in case of repeated attempt.
    errorElement.textContent = '';

    this.email = inputElement.value.trim();

    if (!isValidEmailAddress(this.email)) {
      errorElement.textContent = 'Invalid email address';
      return;
    }

    const input: SubscribeInput = {
      acceptsMarketing: !!marketingElement.checked,
      email: this.email,
      productId: this.productId,
      productTitle: this.productTitle,
      productUrl: this.productUrl,
      sku: this.sku,
      variantId: this.variantId,
      variantTitle: this.variantTitle
    };

    let responseBody;
    try {
      // Reset marketing consent checkbox after successful form submission
      marketingElement.checked = false;
      responseBody = await subscribe(input);
    } catch (error) {
      // Simulate a response so the common error handling code is simpler.
      responseBody = <BackInStockTypes.ResponseBody>{
        status: 'Error',
        errors: {
          base: ['Uh oh! Something went wrong. Please try again later.']
        }
      };
    }

    if (responseBody.status !== 'OK') {
      const errorMessage = findErrorMessage(responseBody);
      if (errorMessage) {
        errorElement.textContent = errorMessage;
        setTimeout(() => errorElement.textContent = '', 5000);
      } else {
        errorElement.textContent = 'Uh oh! Something went wrong. Please try again later.';
        console.warn('Unexpected Back in Stock response body', responseBody);
      }

      return;
    }

    // On success, ensure there is no visible error from a previous attempt
    errorElement.textContent = '';

    // Clear the input email address
    inputElement.value = '';

    // Show a new view.
    this.renderSecondView();
  }

  private onFormSubmitClick(event: Event) {
    event.preventDefault();
    this.subscribe().catch(console.warn);
  }

  private renderSecondView() {
    const modalContentToHide = this.shadowRoot.querySelector('.before-submit');
    modalContentToHide.classList.add('hidden');
    const modalContentToShow = this.shadowRoot.querySelector('.after-submit');
    modalContentToShow.classList.remove('hidden');
    const continueShoppingButton = this.shadowRoot.querySelector<HTMLElement>('.continue');
    continueShoppingButton.addEventListener('click', this.closeModalBound);
    continueShoppingButton.focus();
  }
}

interface SubscribeInput {
  acceptsMarketing: boolean;
  email: string;
  productId: number;
  productTitle: string;
  productUrl: string;
  sku: string;
  variantId: number;
  variantTitle: string;
}

/**
 * Signs up a user to receive an email notification when a product is available for sale again
 * because its available inventory increased. Returns a promise that resolves when the subscription
 * completes.
 *
 * Upon a successful subscription, we record the email address in a cookie so that analytics
 * integrations can access it on page load for purposes of enhanced matching. If the subscription
 * was not successful then the previous cookie, if one exists, is left as is.
 */
async function subscribe(input: SubscribeInput) {
  type Detail = BISFormSubmittedEvent['detail'];

  // Since the opt-in checkbox is optional and used to add customers to a specific Klaviyo list, we
  // need to check if it is checked and set the value to true if it is, otherwise it is undefined.
  // This will prevent the customer from being removed from the list on any subsequent notify me
  // form submissions where they negate to check the opt-in checkbox.
  const acceptsMarketing = input.acceptsMarketing ? input.acceptsMarketing : undefined;

  if (typeof input.email !== 'string' || input.email.length === 0) {
    const event = new CustomEvent<Detail>('bis-form-submitted', {
      detail: {
        accepts_marketing: acceptsMarketing,
        email: input.email,
        event_id: uuid.v4(),
        product_id: input.productId,
        product_title: input.productTitle,
        product_url: input.productUrl,
        sku: input.sku,
        success: false,
        variant_id: input.variantId,
        variant_title: input.variantTitle
      }
    });

    dispatchEvent(event);

    throw new Error('Cannot subscribe to Back In Stock because email is invalid');
  }

  // This function should never be called when BIS is uninitialized. We do however see some rare
  // errors in the wild where it is uninitialized. Check for this and error immediately.

  if (typeof window.BIS?.create !== 'function') {
    const event = new CustomEvent<Detail>('bis-form-submitted', {
      detail: {
        accepts_marketing: acceptsMarketing,
        email: input.email,
        event_id: uuid.v4(),
        product_id: input.productId,
        product_title: input.productTitle,
        product_url: input.productUrl,
        sku: input.sku,
        success: false,
        variant_id: input.variantId,
        variant_title: input.variantTitle
      }
    });

    dispatchEvent(event);

    throw new Error('Cannot subscribe because Back In Stock Service is not initialized');
  }

  // The BIS create function does not return a real promise. There is no catch method of the thing
  // that the function returns. However, since the returned thing has a then method, we can await
  // it. It might seem excessive to wrap a promise in another promise, but since we cannot simply
  // return the call itself as a promise, as this would surprise callers that expect a real promise,
  // we have to wrap the return value within another promise, which is why the outer function is
  // async.

  const object = await window.BIS.create(input.email, input.variantId, input.productId,
    { accepts_marketing: acceptsMarketing }
  );

  const event = new CustomEvent<Detail>('bis-form-submitted', {
    detail: {
      accepts_marketing: acceptsMarketing,
      email: input.email,
      event_id: uuid.v4(),
      product_id: input.productId,
      product_title: input.productTitle,
      product_url: input.productUrl,
      sku: input.sku,
      success: object?.status === 'OK',
      variant_id: input.variantId,
      variant_title: input.variantTitle
    }
  });
  dispatchEvent(event);

  return object;
}

/**
 * Searches the response body from BackInStock for the presence of an error message and returns the
 * first error message found.
 *
 * The BIS docs say one thing but the real service does something different. It sometimes returns an
 * undocumented "email" property that is an array of strings representing errors, and does not
 * return a base property.
 */
function findErrorMessage(body: Partial<BackInStockTypes.ResponseBody>) {
  if (Array.isArray(body.errors?.base) && body.errors.base.length > 0 &&
    typeof body.errors.base[0] === 'string' && body.errors.base[0].length > 0) {
    return body.errors.base[0];
  } else if (Array.isArray(body.errors?.email) && body.errors.email.length > 0 &&
    typeof body.errors.email[0] === 'string' && body.errors.email[0].length) {
    return body.errors.email.join('\n');
  }
}

/**
 * Fired when the out of stock form for a product is submitted.
 *
 * This is **not** only fired when the submission was successful. Listeners need to check the
 * success property.
 */
type BISFormSubmittedEvent = CustomEvent<Partial<{
  /**
   * Reflects state of accepts marketing checkbox on the BIS form, is passed via the BIS integration
   * to a specific Klaviyo list.
   */
  accepts_marketing: boolean;
  /**
   * The value of the email address input. This is not validated before the event is dispatched. The
   * original input email address used for subscription is included here, which might be slightly
   * different than the email actually subscribed (e.g. the back in stock service might lowercase).
   */
  email: string;

  event_id?: string;

  /**
   * Id of the Shopify product near which the form was displayed
   */
  product_id: number;

  /**
   * Product title
   *
   * @example "Le Volume"
   */
  product_title: string;

  /**
   * Value of the Shopify product url near which the oos form was displayed, this may not be an
   * absolute url
   */
  product_url: string;

  /**
   * Variant SKU
   *
   * @example "2350"
   */
  sku: string;

  /**
   * Whether the Back-In-Stock registration was successful.
   */
  success: boolean;

  /**
   * Id of the Shopify variant near which the OOS form was displayed.
   */
  variant_id: number;

  /**
   * Variant title.
   */
  variant_title: string;
}>>;

declare global {
  interface WindowEventMap {
    'bis-form-submitted': BISFormSubmittedEvent;
  }

  interface HTMLElementTagNameMap {
    'notify-me-modal': NotifyMeModal;
  }
}

if (!customElements.get('notify-me-modal')) {
  customElements.define('notify-me-modal', NotifyMeModal);
}
